import React from "react"
import { Link } from "gatsby"
import { Row, Col, Container, Breadcrumb, Image, Figure } from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import LuteinComplexMobile from "./../../images/lutein-specialisti-header.jpg"
import LuteinComplexTablet from "./../../images/lutein-specialisti-header.jpg"
import LuteinComplexDesktop from "./../../images/lutein-specialisti-header.jpg"
import LuteinComplexXL from "./../../images/lutein-specialisti-header.jpg"

import DrAtanasova from "./../../images/lut-virusi-d-r-atanasova.jpg"
import DrTaneva from "./../../images/lut-virusi-d-r-taneva.jpg"

import Feature5 from "./../../images/lutein-table-mobile.svg"
import Feature5xl from "./../../images/lutein-table-xl.svg"

import suhoOko from "./../../images/lutein-specialisti-suho-oko.jpg"
import veskaStoyanova from "./../../images/lutein-specialisti-VESKA_STOYANOVA.jpg"
import nevenaBojkova from "./../../images/lutein-specialisti-nevena-bojkova.jpg"
import noshtnoVijdane from "./../../images/lutein-specialisti-noshtno-vijdane.jpg"
import maculnaDegeneracia from "./../../images/lutein-specialisti-makulna-degeneracia.jpg"
import danielaPaikova from "./../../images/lutein-specialisti-daniela-paikova.jpg"
import ginkaTodorova from "./../../images/lutein-specialisti-ginka_todorova.jpg"
import drBuhlev from "./../../images/lutein-specialisti-buhlev.jpg"
import drKirchev from "./../../images/lutein-specialisti-d-r-kirchev.jpg"


const LuteinSpecialisti = () => (
  <Layout backButton={true} pageInfo={{ pageName: "lutein-specialisti" }}>
    <Seo title="Специалистите: Как да виждаме по-добре?" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/articles">СТАТИИ</Link>
            </li>
            <Breadcrumb.Item active>
              СПЕЦИАЛИСТИТЕ - КАК ДА ВИЖДАМЕ ПО-ДОБРЕ
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Специалистите: Как да виждаме по-добре?"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={12} className="">
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Специалистите: Как да виждаме по-добре?
          </h1>
          <h2 className="font-light">
            <em>
              Лекари от цялата страна отдавна са открили един ефективен подход
            </em>
          </h2>
        </Col>
        <Col sm={12}>
          <p>
            Черни петна, шарени кръгове, вълнички на хоризонта, размазване – и
            само едно от изброените да сме „видели“, то е достатъчно, за да ни
            стане ясно, че проблемите с очите са „почукали на вратата ни“. Пътят
            от „танцуващите“ букви до загубата на зрението е кратък, а
            най-лошото е, че тежките проблеми с очите възникват почти
            незабележимо и не болят.
          </p>
          <p>
            Сблъскали се не веднъж и дваж със случаи на тривиални оплаквания,
            прераснали в сериозни очни проблеми, за да оправят зрението на
            своите пациенти и да ги предпазят от необратими последици,
            офталмолозите от цялата страна отдавна се доверяват на едно
            ефективно средство – природните капсули „Лутеин Комплекс Плюс“.
            Продуктът отдавна спечелил доверието на вещите медици заради своя
            бърз и безусловен ефект при широк спектър от очни болести. Те с
            увереност го прилагат не само при пациенти с уморени очи например, а
            и при състояния като катаракта (перде на окото), възрастово-свързана
            макулна дегенерация и други, и отчитат много добри резултати.
            Всичко, единодушни са лекарите, се корени в специализираната формула
            на продукта, която съдържа доказано най-необходимите за очите
            естествени вещества.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={7}>
          <h4>ПОМОЩ! ИМАМ ПЕРДЕ</h4>
          <p>
            Бялото платно, което покрива окото, не само изглежда неприятно – то
            е истински тормоз за своя „притежател“. Всичко започва като леко
            замъгляване на зрението и затруднения при четене, докато се стигне
            до двойни образи и виждане на кръгове около запалени лампи.
            Заслугата да ни споходи катарактата е на годините, които, особено
            подминем ли 50-те, освен с посивели коси ни „даряват“ и с перде.
            Забавяйки стареенето на окото, „Лутеин Комплекс Плюс“ забавя и
            развитието на пердето като в същото време значително подобрява
            зрението и ни спасява от скъпоструващи операции.
          </p>
        </Col>
        <Col sm={5}>
          <Image className="w-100" src={suhoOko} />
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={4}>
          <Figure className="mr-3 mb-3 w-100 float-left">
            <Figure.Image
              alt="Д-р Василка Атанасова, офталмолог, Пловдив"
              src={DrAtanasova}
            />
            <Figure.Caption>
              Д-р Василка Атанасова, офталмолог, Пловдив
            </Figure.Caption>
          </Figure>
        </Col>
        <Col sm={8}>
          <h5>
            <strong>ЛУТЕИН КОМПЛЕКС ПЛЮС помага срещу катаракта (перде)</strong>
          </h5>
          <p>
            „Препоръчвам ЛУТЕИН КОМПЛЕКС ПЛЮС, тъй като съдържа комплекс от
            най-добрите съставки и екстракти в грижата за очите със силно
            антиоксидантно действие и помага за поддържане на нормална зрителна
            функция и добро зрение.
          </p>
          <p>
            Формулата на ЛУТЕИН КОМПЛЕКС ПЛЮС е съставена от мощните
            антиоксиданти лутеин и зеаксантин в комбинация с екстракт от черна
            боровинка плюс цинк, витамин Е и витамин С. Проучванията сочат, че
            по-високият прием на лутеин и зеаксантин, съдържащи се в
            концентрирано количество в продукта, е свързан с намаляване на риска
            от катаракта (перде на окото). В допълнение, тези две вещества в
            ЛУТЕИН КОМПЛЕКС ПЛЮС имат способността да абсорбират светлината от
            синята зона на видимия спектър и така предпазват очите от сериозните
            щети, които им нанася слънцето. Благодарение на екстракта от черна
            боровинка той укрепва съдовете на очите, а чрез цинка допринася за
            поддържане на нормално зрение с благоприятен ефект и в лещата на
            окото.
          </p>
        </Col>
        <Col>
          <p>
            ЛУТЕИН КОМПЛЕКС ПЛЮС е единственият продукт, произведен в течна
            форма за бързо и лесно усвояване. Приемайте го по 1 капсула дневно
            след храна в продължение на поне 3 месеца. На лицата в риск от
            развитие на очни заболявания, както и на страдащите от такива,
            препоръчвам да провеждат ежегодно поне по един тримесечен
            терапевтичен курс с ЛУТЕИН КОМПЛЕКС ПЛЮС.“
          </p>

          <p>
            <i>Д-р Василка Атанасова, офталмолог, Пловдив</i>
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <p>
            Усетила на собствения си гръб тежестта на товара всеки следващ ден
            да виждаш все по-зле от предходния, сънародничката ни Невена Божкова
            избрала да се опре на рамото на „Лутеин Комплекс Плюс“ и не сбъркала
            с решението си. Тя не само се отървала от операцията, но и
            значително подобрила качеството си на живот.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={9}>
          <h4>Спасих се от операция на очите</h4>
          <p>
            „Преди 6 години ми откриха глаукома и катаракта на едното око.
            Пердето беше готово за операция и при всеки контролен преглед ми
            напомняха, че пердето „презрява“ и е наложително да се оперира.
            Междувременно беше започнало образуването на перде и на другото око.
            По ред съображения не се реших на операция.
          </p>
          <p>
            Бях чела доста за действието на „Лутеин Комплекс Плюс“ с черна
            боровинка и реших да започна да го приемам според указанията. Исках
            да предпазя или поне колкото е възможно да забавя развитието на
            пердето на второто око. Пих продукта 6 месеца. Отидох на преглед
            заради глаукомата. Този път лекарката не ми спомена за операция.
            След като я запитах какво става с пердето на окото, тя ми отговори,
            че може да се оперира есента или другата пролет. Отидох на преглед
            при друг лекар. И той ми каза същото – има перде, но не се налага
            операция. А аз чувствам, че мъглата пред окото ми започна да
            оредява.
          </p>
          <p>
            Продължавам да пия „Лутеин Комплекс Плюс“. Очевидно е, че той
            изпълнява мисията си, стига да му се доверим. Препоръчвам го на
            всички, които са в моето състояние, и от сърце благодаря на хората,
            които стоят зад този ефикасен продукт и го правят достъпен за нас.“
          </p>
          <p>
            <i>Невена Божкова, 72 год.</i>
          </p>
        </Col>
        <Col sm={3}>
          <Figure className="mr-3 mb-3 w-100 float-right">
            <Figure.Image
              alt="Д-р Василка Атанасова, офталмолог, Пловдив"
              src={nevenaBojkova}
            />
            <Figure.Caption>Невена Божкова, 72 год.</Figure.Caption>
          </Figure>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={9}>
          <h4>Преборих пердето</h4>
          <p>
            Аз съм на 80 години и от десет години пия непрекъснато Лутеин
            Комплекс Плюс. Започнах, когато докторът ми откри зачатъци на перде.
            Много се уплаших. Той ми каза да пия тоя Лутеин Комплекс Плюс всеки
            ден по 1 капсула.
          </p>
          <p>
            И сега да се похваля – перде нямам, не нося очила, чета си
            програмата във вестника без очила. Имам две дъщери и трима внуци. Те
            нали с компютри работят, сега и те пият Лутеин Комплекс Плюс и са
            много доволни. А аз съм благодарна, защото помогна на цялото ми
            семейство!
          </p>
          <p>
            <i>Веска Стоянова, 80 г. от Велинград</i>
          </p>
        </Col>
        <Col sm={3}>
          <Figure className="mr-3 mb-3 w-100 float-right">
            <Figure.Image
              alt="Веска Стоянова, 80 г. от Велинград"
              src={veskaStoyanova}
            />
            <Figure.Caption>Веска Стоянова, 80 г. от Велинград</Figure.Caption>
          </Figure>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <h4>КОШМАРЪТ „МАКУЛНА ДЕГЕНЕРАЦИЯ“</h4>
          <Image className="w-25 p-3 float-left" src={maculnaDegeneracia} />
          <p>
            Наистина ли е толкова страшна възрастово-свързаната макулна
            дегенерация? Да, тя постепенно влошава зрението и прогресира с всеки
            изминал ден, заплашвайки с течение на времето съвсем да ни лиши от
            централно зрение. Казват, че това била болестта на индустриалния
            свят, а жертвите ѝ са възрастните хора, трудили се цял живот.
          </p>
          <p>
            Спрягана за нелечима, макулната дегенерация може да бъде забавена, а
            дори и спряна с природни средства. Точно тези „спасители“ на
            зрението се съдържат в „Лутеин Комплекс Плюс“. На тях се дължи
            положителният ефект върху зрението, който забелязали лекарите от
            цялата страна, сред които и д-р Даниела Пайкова от Ловеч.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={8}>
          <h4>Само с 1 капсула дневно помага при дегенерация на макулата</h4>
          <p>
            „Използвам „Лутеин Комплекс Плюс“ при пациенти с диагностицирана
            Възрастово-свързана макулна дегенерация (ВСМД). При ВСМД централното
            зрение се замъглява, при четене думите могат да изглеждат размазани,
            цветовете изглеждат бледи, а правите линии изглеждат изкривени.
          </p>
          <p>
            Установила съм, че след поне 3-месечен терапевтичен курс с прием на
            „Лутеин Комплекс Плюс“ в доза от по 1 капсула на ден, проявленията
            на симптоматиката намаляват и прогресията на заболяването значително
            се забавя. Като офталмолог изключително добро впечатление ми направи
            това, че в продукта се съдържат голямо количество антиоксиданти.
            Приемът на антиоксиданти е свързан с по-малък риск от развитие на
            ВСМД.“
          </p>
          <p>
            <i>Д-р Даниела Пайкова, офталмолог, Ловеч</i>
          </p>
        </Col>
        <Col sm={4}>
          <Figure className="mr-3 mb-3 w-100 float-right">
            <Figure.Image
              alt="Д-р Даниела Пайкова, офталмолог, Ловеч"
              src={danielaPaikova}
            />
            <Figure.Caption>
              Д-р Даниела Пайкова, офталмолог, Ловеч
            </Figure.Caption>
          </Figure>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <p>
            Към положителните клинични наблюдения на своята ловешка колежка се
            присъединява и офталмологът д-р Кирил Кирчев от морската ни столица.
            Той е забелязал, че освен подобряване състоянието на очите при
            макулна дегенерация „Лутеин Комплекс Плюс“ възвръща ясното виждане и
            контрастността както при хора в третата възраст, така и при лица в
            трудоспособна възраст, чиито очи са постоянно подложени на вредни
            външни фактори.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={4}>
          <Figure className="mr-3 mb-3 w-100 float-left">
            <Figure.Image
              alt="Д-р Кирил Кирчев, офталмолог, ДКЦ-III, Варна"
              src={drKirchev}
            />
            <Figure.Caption>
              Д-р Кирил Кирчев, офталмолог, ДКЦ-III, Варна
            </Figure.Caption>
          </Figure>
        </Col>
        <Col sm={8}>
          <h4>„Лутеин Комплекс Плюс“ предпазва очите от дегенерация</h4>
          <p>
            „Продуктът „Лутеин Комплекс Плюс“ е предпочитан от мен, заради своя
            умело подбран и прецизно дозиран състав. Включените в него
            високоефективни антиоксиданти – витамин С, витамин Е, цинк, екстракт
            от боровинка, лутеин и зеаксантин, имат изключително добро
            въздействие върху дейността на очите. Благодарение на високите
            концентрации лутеин в него, обогатен с чист зеаксантин, той
            подобрява ясното виждане и контрастността и предпазва от дегенерация
            на макулата, свързана с възрастта. Екстрактът от боровинка, който е
            богат източник на мощни антиоксиданти, подобрява кръвоснабдяването и
            адаптивните възможности на окото, подпомага по-бързото му
            възстановяване.
          </p>
          <p>
            Прилагам „Лутеин Комплекс Плюс“ в практиката си при две групи
            пациенти. Едната са по-възрастни хора, които имат нерационално
            хранене и недостиг на полезни вещества в организма, което отключва
            редица очни проблеми. Втората група са лица, чиято работа (или
            ежедневието им) предполага постоянно излагане на очите на вредни
            фактори – замърсен въздух и тютюнопушене, слънчеви лъчи, компютър,
            изкуствена светлина и т.н. И при двете групи наблюдавам добър ефект
            от приложението на „Лутеин Комплекс Плюс“. Продуктът им понася
            добре, няма никакви странични ефекти и се комбинира успешно с
            различни лекарствени средства.“
          </p>
          <p>
            <i>Д-р Кирил Кирчев, офталмолог, ДКЦ-III, Варна</i>
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <p>
            В подкрепа на лекарските наблюдения са и резултатите, които отчитат
            техните пациенти. Масово хората, доверили се на „Лутеин Комплекс
            Плюс“, са доволни, а мълвата за неговата ефективност се носи от уста
            на уста. Точно така – от устата на съседка – е научила за него и
            68-годишната Гинка Тодорова, чието зрение е било сериозно
            застрашено.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={9}>
          <h4>Щастлива съм, защото отново виждам</h4>
          <p>
            Мили хора, щастието на човека се крие в това дали е здрав или не.
            Когато е добре, някак си другото е без значение. Разбрах го, когато
            преди година започнах почти да не виждам. Всички доктори бяха
            категорични в лошите си прогнози за моето зрение. Очакваше ме едва
            ли не слепота! А аз останах сам-сама на този свят, кой ще ме гледа?!
          </p>
          <p>
            Съседка тогава ми каза, че е чела по вестниците за „Лутеин Комплекс
            Плюс“ и е проверявала в аптеките – не бил скъп. Помогнал е на много
            хора, да си го купя и аз. Взех го с недоверие, но започнах да го пия
            всеки ден, точно както е препоръчано.
          </p>
          <p>
            Е, слава Богу, ден след ден усещах подобрение. Започнах да си виждам
            по-добре, дори сега шия и на машината. Вземам го и до ден днешен –
            всяка година си правя по един курс на прием от 5–6 месеца. Вълшебен
            е! Благодарение на „Лутеин Комплекс Плюс“виждам отново.“
          </p>
          <p>
            <i>Гинка Тодорова, 68 год.</i>
          </p>
        </Col>
        <Col sm={3}>
          <Figure className="mr-3 mb-3 w-100 float-right">
            <Figure.Image alt="Гинка Тодорова, 68 год." src={ginkaTodorova} />
            <Figure.Caption>Гинка Тодорова, 68 год.</Figure.Caption>
          </Figure>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <h4>
            АКО ОЧИТЕ ВИ „ПАРЯТ“ И УСЕЩАТЕ СТЪРГАНЕ, КОГАТО МИГАТЕ, ТОВА Е „СУХО
            ОКО“
          </h4>

          <p>
            Синдромът „Сухо око“ е най-разпространеният очен проблем и засяга
            хора от 5 до 105-годишна възраст. Младите го развиват заради
            постоянното взиране в екрани и офисната работа в затворени помещения
            на лампи, а възрастните – те пък заради еднотипното хранене и
            недостигът на полезни вещества в организма.
          </p>
          <p>
            Най-ефективният метод за премахване на зачервените очи, паренето и
            острите „пробождания“ е интензивното подхранване отвътре със
            специалния комплекс вещества в „Лутеин Комплекс Плюс“. Той набавя
            точно нещата, от които очите се нуждаят, и възстановява нормалното
            състояние на очите независимо от възрастта.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={8}>
          <h5>
            <strong>
              Препоръчвам ЛУТЕИН КОМПЛЕКС ПЛЮС при зрителна умора и сухота в
              очите
            </strong>
          </h5>
          <p>
            „Синдромът на сухото око е многофакторно заболяване на сълзите и
            очната повърхност. Води до дискомфорт, разкъсване на слъзния филм,
            увреждане на очната повърхност и зрителни нарушения. След
            40–50-годишна възраст слъзната секреция намалява. Чести причини са
            начинът на живот, приемът на лекарства, възпалителни процеси, имунни
            дефицити.
          </p>
          <p>
            Всички сухи и уморени очи се нуждаят от защита! Моята препоръка в
            тези случаи е насочена към прием на продукта{" "}
            <Link to="/lutein-complex">ЛУТЕИН КОМПЛЕКС ПЛЮС</Link>, тъй като той
            има редица благотворни ефекти върху здравето на очите. Клинично
            изпитаната хранителна добавка ЛУТЕИН КОМПЛЕКС ПЛЮС е активна грижа
            зрението. Тя намалява умората в очите, подобрява зрителната острота,
            подобрява нощното виждане и намалява и забавя риска от възрастовата
            макулна дегенерация на катарактата.
          </p>
        </Col>
        <Col sm={4}>
          <Figure className="mr-3 mb-3 w-100 float-right">
            <Figure.Image
              alt="Д-р Нелидия Танева, офталмолог, частен кабинет в бл. 383, ж.к. Младост 3, София"
              src={DrTaneva}
            />
            <Figure.Caption>
              Д-р Нелидия Танева, офталмолог, частен кабинет в бл. 383, ж.к.
              Младост 3, София
            </Figure.Caption>
          </Figure>
        </Col>
        <Col>
          <p>
            ЛУТЕИН КОМПЛЕКС ПЛЮС е добре да бъде приеман поне 3 месеца и се
            препоръчва при лица:
          </p>
          <ul>
            <li>Прекарващи часове пред компютър</li>
            <li>Работещи на изкуствено осветление</li>
            <li>Фамилно обременени с очни заболявания“</li>
          </ul>

          <p>
            <i>
              Д-р Нелидия Танева, офталмолог, частен кабинет в бл. 383, ж.к.
              Младост 3, София
            </i>
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={5}>
          <Image className="w-100" src={noshtnoVijdane} />
        </Col>
        <Col sm={7}>
          <h4>ЗРЕНИЕ КАТО НА КОТКА</h4>
          <p>
            Всекиму е известно, че котките виждат през нощта, а ние, хората, не.
            Но дори и да не сме като тях, нашите очи също имат известни
            възможности да се справят в тъмнината, които обаче с течение на
            времето отслабват.
          </p>
          <p>
            За да не се лутаме като изгубени в тъмнината и опипом да търсим
            ключа на лампата вкъщи, очите ни трябва да се адаптират добре както
            към светлина, така и към мрак. „Лутеин Комплекс Плюс“ има доказано
            въздействие именно в тази посока – той помага да виждаме добре
            независимо колко оскъдна е светлината около нас.
          </p>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={8}>
          <h5>
            <strong>„Лутеин Комплекс Плюс“ подобрява нощното виждане</strong>
          </h5>
          <p>
            „Предписвам „Лутеин Комплекс Плюс“ на моите пациенти при широк
            спектър от проблеми с очите и зрението, защото той ми доказа своята
            ефективност. Съставът му е експертно формулиран, така че да
            осигурява най-необходимите за очите вещества.
          </p>
          <p>
            Назначавам „Лутеин Комплекс Плюс“ при пациенти с дегенерация на
            макулата, при увреда на зрението и при оплаквания от премрежване и
            замъгляване на погледа независимо от осветеността. Той укрепва
            капилярите в очната ябълка и подобрява адаптацията на окото към
            светлина и тъмнина. Благодарение на това се изостря зрението, дори и
            през нощта.
          </p>
          <p>
            Произведен по иновативна технология, „Лутеин Комплекс Плюс“ се
            усвоява пълноценно от организма и дава видимо по-бързи резултати.
            Приемът му е удобен за пациентите – само 1 капсула дневно.“
          </p>
          <p>
            <i>
              Д-р Христо Бухлев, офталмолог, МЦ по Очна микрохирургия, Петрич
            </i>
          </p>
        </Col>
        <Col sm={4}>
          <Figure className="mr-3 mb-3 w-100 float-right">
            <Figure.Image
              alt="Д-р Христо Бухлев, офталмолог, МЦ по Очна микрохирургия, Петрич"
              src={drBuhlev}
            />
            <Figure.Caption>
              Д-р Христо Бухлев, офталмолог, МЦ по Очна микрохирургия, Петрич
            </Figure.Caption>
          </Figure>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <h4>ЗАЩО „ЛУТЕИН КОМПЛЕКС ПЛЮС“ РАБОТИ ТОЛКОВА ДОБРЕ?</h4>

          <p>
            „Лутеин Комплекс Плюс“ е единствен по рода си у нас. Той
            представлява експертна формула, включваща съставки с медицински
            доказан ефект върху зрението, които са в специална течна форма,
            осигуряваща пълноценното усвояване на полезните вещества в тялото,
            тяхното адекватно разпределяне към най-невралгичните точки и
            безпрецедентно бързите резултати. „Лутеин Комплекс Плюс“ осигурява
            трите най-важни неща, които ти гарантират, че получаваш най-доброто:
          </p>
          <ul>
            <li>Бързо възстановяване</li>
            <li>Комплексно действие</li>
            <li>Надежден резултат</li>
          </ul>
          <p>
            Всяка съставка е умело подбрана съобразно нейното въздействие върху
            здравето на очите. Прецизно дозирани и комбинирани те работят
            синергично и взаимно усилват своите положителни ефекти. Проведените
            клинични проучвания върху тях доказват благотворното влияние на
            „Лутеин Комплекс Плюс“ при редица очни проблеми.
          </p>
          <p>
            <strong>Облекчава натоварването върху очите:</strong> лутеинът и
            зеаксантинът имат способността да абсорбират синята светлина от
            спектъра. Поради нейната близост до UV светлината тя е опасна и
            уврежда зрението. Намалявайки проникването на синя светлина в окото,
            „Лутеин Комплекс Плюс“ предпазва от появата на проблеми като
            катаракта и забавя тяхната прогресия.
          </p>
          <p>
            <strong>
              Намалява пораженията върху очите, свързани с възрастта:
            </strong>{" "}
            посредством елиминирането на свободните радикали в клетките „Лутеин
            Комплекс Плюс“ ограничава пораженията, които напредването на
            възрастта оказва върху тъканите на очите. Това е предпоставка за
            понижаване риска от развитие на възрастово свързана макулна
            дегенерация и възпрепятстване на нейното задълбочаване.
          </p>
          <p>
            <strong>
              Предпазва от вредните въздействия на околната среда:
            </strong>{" "}
            чрез добавения цинк и витамин С „Лутеин Комплекс Плюс“ защитава
            лещата, ретината, макулата и роговицата на окото от негативни външни
            влияния. Той е особено ценен за пушачи; за хора, работещи пред
            компютър или в затворени помещения; за лица с хронични заболявания,
            ежедневно приемащи тежки лекарства и др.
          </p>
          <p>
            <strong>Подобрява нощното виждане:</strong> специалният екстракт от
            черна боровинка в „Лутеин Комплекс Плюс“ подпомага регенерацията
            на зрителните пигменти в клетките на ретината. Така подобрява
            адаптивните функции и възможности на окото към светлина и изостря
            нощното виждане.
          </p>
          <p>
            „Лутеин Комплекс Плюс“ е изключително ценен при замъглено зрение
            вследствие на перде, увреждане на макулата, умора на очите и синдром
            на сухото око, работа с компютър и др.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <h4>
            Клинично потвърдена ефективност на съставките в „Лутеин Комплекс
            Плюс“
          </h4>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col md={4}>
          <p>
            В допълнение към качествата на „Лутеин Комплекс Плюс“ е и неговата
            удобна дозировка от само 1 капсула на ден. Продуктът е добре да бъде
            приеман поне 3 месеца, за да бъдат постигнати максимални и устойчиви
            във времето резултати. Той е изцяло природен и спокойно се
            съвместява с други лекарства. „Лутеин Комплекс Плюс“ може да бъде
            закупен дори без рецепта както от всяка аптека в страната, така и в
            електронния магазин на фирмата вносител.
          </p>
        </Col>
        <Col md={8}>
          <picture>
            <source srcSet={Feature5xl} media="(min-width: 767px)" />
            <source srcSet={Feature5} media="(min-width: 370px)" />
            <img
              srcSet={Feature5}
              alt="Клинично потвърдена ефективност"
              className="mb-4 mw-100 mx-auto d-block"
            />
          </picture>
        </Col>
      </Row>

      <Row className="product-container">
        <Col>
          <p>
            Можете да откриете ЛУТЕИН КОМПЛЕКС ПЛЮС в най-близката до вас
            аптека.
          </p>
          <p>
            Телефон за консултация и поръчка:{" "}
            <a href="tel:029446006">02 944 60 06</a>
          </p>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <h3>
            <Link to="/lutein-complex">
              Повече информация за ЛУТЕИН КОМПЛЕКС ПЛЮС можете да намерите тук.
            </Link>
          </h3>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <p>
            *Да не се превишава препоръчваната дневна доза! Продуктът да не се
            използва като заместител на разнообразното хранене!
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default LuteinSpecialisti
